import { Component, OnInit } from '@angular/core';
import { SharedserviceService } from 'src/app/services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  getData:any;

  constructor(public sharedService: SharedserviceService) { }

  ngOnInit() {
    this.contactDetail();
  }
  scrollTop(){
    window.scroll({
      top:0,
      left:0,
      behavior:'smooth'
    });
  }
    contactDetail() {

      const self = this;
      self.sharedService.get('v1/users/get_website_owner_data').subscribe((res: any) => {
        if (res.replyCode == "success") {
          this.getData = res.data;
          console.log("::::::::::::::",this.getData);
          sessionStorage.setItem("ownerData",JSON.stringify(this.getData));
        } else {
          
        }
      });
    }
  

}
